$swal-red: #f27474;

.swal-icon--error {
  border-color: $swal-red;
  animation: animateErrorIcon 0.5s;

  &__x-mark {
    position: relative;
    display: block;
    animation: animateXMark 0.5s;
  }

  &__line {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: $swal-red;
    display: block;
    top: 37px;
    border-radius: 2px;

    &--left {
      transform: rotate(45deg);
      left: 17px;
    }

    &--right {
      transform: rotate(-45deg);
      right: 16px;
    }
  }
}

@keyframes animateErrorIcon {
  from {
    transform: rotateX(100deg);
    opacity: 0;
  }

  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}
