$grid-gutter-width: 20px;
$flashes-width: 457px;
$flashes-space: $grid-gutter-width * 0.5;
$flash-text-line-height: 24px;
$flash-vertical-padding: 15px;
$flash-possible-height: $flash-text-line-height + $flash-vertical-padding * 2;
$flash-bg-opacity: 0.8;
$flash-enter-transition-duration: 350ms;
$flash-exit-transition-duration: 300ms;

.b-flashes {
  pointer-events: auto;
  max-width: $flashes-width;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $flashes-space $flashes-space $flashes-space $flashes-space * 3;

  &___wrapper {
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9100;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .flash {
    line-height: $flash-text-line-height;
    color: var(--bs-white);
    padding: $flash-vertical-padding 20px;
    border-radius: var(--bs-border-radius);
    background: rgba(var(--bs-secondary-rgb), $flash-bg-opacity);
    border: 1px solid rgba(var(--bs-white), 0.5);
    opacity: 1;
    transform: none;
    margin: $flashes-space 0 0 0;
    transition:
      opacity $flash-exit-transition-duration ease,
      transform $flash-exit-transition-duration ease;
    z-index: 2;

    &.alert,
    &.nightmare {
      background: rgba(var(--bs-danger-rgb), $flash-bg-opacity);
    }

    &.error {
      background: rgba(var(--bs-danger-rgb), $flash-bg-opacity);
    }

    &.notice {
      background: rgba(var(--bs-success-rgb), $flash-bg-opacity);
    }

    ul {
      margin: 0;
      padding-left: 16px;
    }

    a {
      color: var(--bs-white);
    }

    &.flash-enter {
      margin-top: -($flash-possible-height + $flashes-space);
      opacity: 0.01;
      z-index: 3;
      transition-duration: $flash-enter-transition-duration;
      transition-timing-function: cubic-bezier(0.42, 0.8, 0.58, 1.2);
      transform: translate($flashes-width, 0) scale(1.1) rotate(-5deg);
    }

    &.flash-enter-active {
      margin-top: $flashes-space;
      transform: translate(0, 0) scale(1) rotate(0deg);
      opacity: 1;
    }

    &.flash-exit {
      opacity: 1;
      transform: translate(0, 0) scale(1) rotate(0deg);
      z-index: 1;
    }

    &.flash-exit-active {
      margin-top: -$flash-possible-height;
      transform: translate($flashes-width, 0) scale(0.8) rotate(-10deg);
      opacity: 0.01;
    }
  }
}
