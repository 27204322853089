// icons from sources of sweetalert 2.1.0
@import "./icons/error";
@import "./icons/success";

.swal-icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  box-sizing: content-box;

  // edited
  margin: auto;
  z-index: 1;
  position: absolute;
  inset: 0;
  background: #fff;
}
