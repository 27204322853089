.planning-popover {
  font-size: 14px !important;

  .avatar {
    min-width: 30px;
  }

  .js-link-to-modal {
    min-width: 24px;
  }

  .time-amount {
    white-space: nowrap;
  }
}
